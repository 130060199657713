<template>
    <div class="countMultiSelectorDiv" :key="adultUpdate">
        <div class="countMultiSelectorTitle">Guests & Villas</div>
        <a-form :form="formSelectCount">
            <div style="display:flex" v-for="cell in keysListPersonCount" :key="cell">
            
                <a-form-item class="countMultiSelectorFormAdult">
                    
                    <a-select class="countMultiSelectorCellAdult" placeholder="Select" v-decorator="[
                        `adultcount[${cell}]`,
                        {
                            initialValue: arr[cell] ? arr[cell].searchAdultCount - 1 : 1,
                            rules: [{ required: false, message: 'room!' }]
                        }
                    ]">
                        <a-select-option v-for="item, index in adultcountList" :key="index" :value="index">
                            <div style="font-family: Montserrat light;">{{ item }}</div>
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <div class="countMultiSelectorFormDiv">
                    <a-form-item class="countMultiSelectorFormChild">
                        
                        <a-select class="countMultiSelectorCellChild" @change="countChanged" placeholder="Select" v-decorator="[
                            `childrencount[${cell}]`,
                            {
                                initialValue: arr[cell] ? arr[cell].searchChildCount : 0,
                                rules: [{ required: false, message: 'room!' }]
                            }
                        ]">
                            <a-select-option v-for="item, index in childcountList" :key="index" :value="index">
                                <div >{{ item }}</div>
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item v-for="_index in childCountInput[cell]" :key="_index" class="countMultiSelectorFormChild">
                        
                        <a-select class="countMultiSelectorCellChild" placeholder="Select" v-decorator="[
                            `childrenage[${cell}][${_index - 1}]`,
                            {
                                initialValue: childages[cell] ? childages[cell][_index - 1] - 1 : 0,
                                rules: [{ required: false, message: 'Please input Children Age.' }]
                            }
                        ]">
                            <a-select-option v-for="item, index in childageList" :key="index" :value="index">
                                <div>{{ item }}</div>
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </div>
                
                <div class="countMultiSelectorBtnDiv">
                    <a-button shape="circle" type="normal" icon="close" @click="removeRowPersonCount(cell)" class="minusRowBtn" :size="'small'"></a-button>
                </div>
                
            </div>
        </a-form>
        <div class="selectCountAddCell">
            <a-button v-show="keysListPersonCount.length < 3" class="selectCountAddBtn" icon="plus" type="primary" @click="addRowPersonCount">
                Add a Villa
            </a-button>
        </div>
        <div class="selectCount">
            <a-button class="selectCountBtn" type="primary" @click="handleCountOk">
                {{isSearchClick ? 'OK' : 'UPDATE'}}
            </a-button>
        </div>
    </div>
</template>
<script>
export default {
    
    data() {
        return {

            formSelectCount:this.$form.createForm(this),
            childCountInput: [],
            childages: [],

            adultcountList:['1 Adult', '2 Adults', '3 Adults', '4 Adults', '5 Adults', '6 Adults', '7 Adults', '8 Adults', '9 Adults', '10 Adults', '11 Adults', '12 Adults'],
            childcountList:['0 Children', '1 Child', '2 Children', '3 Children', '4 Children', '5 Children', '6 Children', '7 Children', '8 Children'],
            childageList:['1 years old', '2 years old', '3 years old', '4 years old', '5 years old', '6 years old', '7 years old', '8 years old', '9 years old', '10 years old', '11 years old', '12 years old', '13 years old', '14 years old', '15 years old'],
            
            idPersonCount: 0,
            keysListPersonCount:[],
            adultUpdate: false
        }
    },
    props: {
        arr: {
            type: Array,
            default: function () {
                return []
            }
        },
        isSearchClick: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    mounted(){
        this.init()
    },
    
    beforeMount() {
        
    },
    created(){
        
    },
    methods: {
        
        init () {
            const arr = [];
            if (this.arr != undefined && this.arr.length !== 0) {
                this.idPersonCount = 0;
                this.childCountInput = [];
                this.childages = [];
                for (let i = 0; i < (this.arr).length; i++) {
                    arr.push(i)
                    this.idPersonCount = this.idPersonCount + 1;
                    this.childCountInput.push(this.arr[i].searchChildCount);
                    this.childages.push(this.arr[i].searchChildAge);
                }
            }
            this.keysListPersonCount = arr;
            if (this.keysListPersonCount.length == 0){
                this.idPersonCount = 1;
                this.keysListPersonCount = [0];
                this.childCountInput = [0];
                this.childages = [];
            }
            
            this.adultUpdate = !this.adultUpdate;
        },
            
        removeRowPersonCount (k) {
            if (this.keysListPersonCount.length === 0) { 
                return
            }
            this.keysListPersonCount = this.keysListPersonCount.filter(item => item !== k)
        },
        addRowPersonCount () {
            this.keysListPersonCount = this.keysListPersonCount.concat(this.idPersonCount)
            this.idPersonCount = this.idPersonCount + 1
        },

    // eslint-disable-next-line no-unused-vars
        countChanged(_count){
            // this.childCountInput = _count;
            
            setTimeout(() => {
                const { formSelectCount: { validateFields } } = this
                validateFields((errors, values) => {
                    if (!errors) {
                        
                        let partOneArr = [];
                        if (values[`adultcount`] != undefined){
                            (values[`adultcount`]).forEach((item, index) => {
                                let _childCount = values[`childrencount`][index];
                                let ageInfoResult = [];
                                for (let i = 0; i < _childCount; i++)
                                    ageInfoResult.push(1);
                                if (values[`childrenage`] != undefined && values[`childrenage`][index] != undefined){
                                    const ageInfoList = values[`childrenage`][index];
                                    ageInfoList.forEach((cell, iCell) => {
                                        ageInfoResult.splice(iCell, 1, cell + 1);
                                    })
                                }

                                const obj = {
                                    searchAdultCount: item,
                                    searchChildCount: _childCount,
                                    searchChildAge: ageInfoResult
                                }
                                partOneArr.push(obj)
                            })
                        }
                        
                        setTimeout(() => {
                            const arr = [];
                            this.idPersonCount = 0;
                            this.childCountInput = [];
                            this.childages = [];

                            for (let i = 0; i < partOneArr.length; i++) {
                                arr.push(i)
                                this.idPersonCount = this.idPersonCount + 1;
                                this.childCountInput.push(partOneArr[i].searchChildCount);
                                this.childages.push(partOneArr[i].searchChildAge);
                            }
                            this.keysListPersonCount = arr;
                            
                            this.adultUpdate = !this.adultUpdate;
                        }, 1);
                    }
                });
            }, 10);

        },
        handleCountOk(){
            
                const { formSelectCount: { validateFields } } = this
                validateFields((errors, values) => {
                    if (!errors) {
                        
                        let partOneArr = [];
                        
                        if (values[`adultcount`] != undefined){
                            (values[`adultcount`]).forEach((item, index) => {
                                let _childCount = values[`childrencount`][index];
                                let ageInfoResult = [];
                                for (let i = 0; i < _childCount; i++)
                                    ageInfoResult.push(0);
                                
                                if (values[`childrenage`] != undefined && values[`childrenage`][index] != undefined){
                                    const ageInfoList = values[`childrenage`][index];
                                    ageInfoList.forEach((cell, iCell) => {
                                        ageInfoResult.splice(iCell, 1, cell + 1);
                                    })
                                }

                                const obj = {
                                    searchAdultCount: item + 1,
                                    searchChildCount: _childCount,
                                    searchChildAge: ageInfoResult
                                }
                                partOneArr.push(obj)
                            })
                        }
                        this.$emit('getCountInfos', partOneArr);
                    }
                });
        },
    }
}
</script>